import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Navbar2 from "../Navbar2";
import { Link } from "react-router-dom";
import Slider from "./Slider/Slider";
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import LoadingSpinner from "../../LoadingSpinner";
import { observer, inject } from 'mobx-react';
import img1 from '../../assets/img5.png';
import img2 from '../../assets/iki.png';
import img3 from '../../assets/uc.png';
import img4 from '../../assets/dort.png';
import Popup from './Popup';
import { isMobile } from "react-device-detect";
import twtlogo from '../../assets/tiwtternocolor.svg';
import fcblogo from '../../assets/facebooknocolor.svg';
import instalogo from '../../assets/instanolcolor.svg';
import youtubelogo from '../../assets/ytbnocolor.svg';

import axios from "axios";

const MainPage = inject()(observer(() => {
  const [isLoading, setIsLoading] = React.useState(true);
  const imgUrl = 'https://megasubdomain.stechomeyazilim.info/';
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [newsData, setData] = useState([]);


  useEffect(() => {
    axios.get('https://getjob.stechomeyazilim.info:5101/getContactMega/select/')
      .then(res => {
        setData(res.data);
      })

      .catch(err => console.log(err));
  }, []);


  const closePopup = () => {
    setIsPopupOpen(false);
  };


  return (
    <div className="w-full h-full ">
      {!isLoading && <Navbar2 />

      }
<div className="bg-[#f9f9f9] w-full h-screen pt-[85px] px-4 lg:px-12">

{/* Sosyal Medya İkonları */}
{newsData.length > 0 &&
  <div className="flex flex-col fixed top-1/3 left-4 space-y-2 z-20">
    <Link to={newsData[0].Contact_Facebook} className="bg-[#3b5998] p-2 rounded-full shadow-md">
      <img src={fcblogo} alt="Facebook" className="w-6 h-6" />
    </Link>
    <Link to={newsData[0].Contact_Twitter} className="bg-[#1DA1F2] p-2 rounded-full shadow-md">
      <img src={twtlogo} alt="Twitter" className="w-6 h-6" />
    </Link>
    <Link to={newsData[0].Contact_Instagram} className="bg-[#DD2A7B] p-2 rounded-full shadow-md">
      <img src={instalogo} alt="Instagram" className="w-6 h-6" />
    </Link>
    <Link to={newsData[0].Contact_Youtube} className="bg-red-500 p-2 rounded-full shadow-md">
      <img src={youtubelogo} alt="YouTube" className="w-6 h-6" />
    </Link>
  </div>
}

{/* Etkinlik Takvimi */}
<Link to="/event" className="fixed top-1/3 right-4 bg-[#33b845] text-white px-4 py-2 rounded-lg shadow-md z-20">
  Etkinlik Takvimi
</Link>

{/* Slider */}
<div className="relative bg-slate-700 w-full h-[250px] sm:h-[300px] md:h-[400px] lg:h-[500px] rounded-lg overflow-hidden shadow-lg">
  <Slider setIsLoading={setIsLoading} />
</div>

{/* Kartlar */}
{isMobile == false ?
  <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 gap-6 mt-5">
    {[
      { link: "/oyuntasarim", color: "#E0536F", text: "Dijital Oyun Tasarım Atölyesi", img: img1 },
      { link: "/yeninesilogrenme", color: "#3DB2E0", text: "Yeni Nesil Öğrenme", img: img3 },
      { link: "/espor", color: "blue", text: "Espor Merkezi", img: img4 },
      { link: "/youtube", color: "red", text: "Youtube Akademi", img: img2 }
    ].map((item, index) => (
      <Link key={index} to={item.link} target="_blank" className="bg-[gray] mb-6 rounded-lg shadow-md transform hover:scale-105 transition duration-300">
        <div className={`w-full p-4 text-center bg-[${item.color}] text-white font-bold rounded-t-lg`}>
          {item.text}
        </div>
        <div className={`bg-[${item.color}] flex justify-center items-center p-6 rounded-b-lg`}>
          <img src={item.img} alt={item.text} className="w-16 h-16" />
        </div>
      </Link>
    ))}
  </div> :
  <div className="mt-5 space-y-6">
  {/* Mobil için kartlar */}
  {[
    { link: "/oyuntasarim", color: "#E0536F", text: "Dijital Oyun Tasarım Atölyesi", img: img1 },
    { link: "/yeninesilogrenme", color: "#3DB2E0", text: "Yeni Nesil Öğrenme", img: img3 },
    { link: "/espor", color: "#E0D726", text: "Espor Merkezi", img: img4 },
    { link: "/youtube", color: "red", text: "Youtube Akademi", img: img2 }
  ].map((item, index) => (
    <Link key={index} to={item.link} className="block bg-[gray] mb-6 rounded-lg shadow-md transform hover:scale-105 transition duration-300">
        <div className={`w-full p-4 text-center bg-[${item.color}] text-white font-bold rounded-t-lg`}>
        {item.text}
      </div>
      <div className={`bg-[${item.color}] flex justify-center items-center p-6 rounded-b-lg`}>
      <img src={item.img} alt={item.text} className="w-12 h-12 md:w-16 md:h-16" />
      </div>
    </Link>
  ))}
</div>

}

{/* Popup ve Yükleme Spinner */}
<Popup isOpen={isPopupOpen} onClose={closePopup} />
<LoadingSpinner show={isLoading} />
</div>

    </div>
  );
}));

export default MainPage;
