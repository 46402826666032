import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const imgUrl = 'https://megasubdomain.stechomeyazilim.info/';

const Popup = ({ isOpen, onClose }) => {
    const popupRef = useRef(null);
    const [newsData, setData] = useState([]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: newsData.length > 0 ? newsData.length : 1 ,
        slidesToScroll: 1
    };

    const [currentImage, setCurrentImage] = useState('');
    const [getCurrentLink, setCurrentLink] = useState('');

    useEffect(() => {
        axios.get('https://getjob.stechomeyazilim.info:5101/getPopupMega/select/')
            .then(res => {
                setData(res.data);
                console.log("lsdnlf",res.data)
                setCurrentImage(imgUrl + res.data[0]?.Popup_PhotoLink);
                setCurrentLink(res.data[0]?.Popup_Weblink);
            })
            .catch(err => console.log(err));
    }, []);

    const handleImageClick = (image,item) => {
        setCurrentImage(image);
        setCurrentLink(item.Popup_Weblink);

    };

    const handleOutsideClick = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            onClose();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);



    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(); // Mobil cihaz olarak kabul ettiğimiz ekran genişliği
        };

        handleResize(); // Başlangıçta kontrol etmek için çağırıyoruz

        window.addEventListener('resize', handleResize); // Ekran boyutu değiştiğinde kontrol etmek için event listener ekliyoruz

        return () => {
            window.removeEventListener('resize', handleResize); // Component kaldırıldığında event listener'ı kaldırıyoruz
        };
    }, []);


    return (
        <>
            {isOpen && (

              
<div className="bg-black bg-opacity-70 fixed inset-0 flex items-center justify-center z-40">
<div className="bg-white rounded-lg shadow-xl overflow-hidden w-full max-w-3xl mx-5" ref={popupRef}>
  <div className="flex justify-between items-center bg-[#006890] p-3">
    <h3 className="text-white font-semibold text-lg">MEGA</h3>
    <button
      className=" text-white rounded-full p-2 transition duration-200"
      onClick={onClose}
    >
      X
    </button>
  </div>

  {/* Image Gallery */}
  <div className="flex items-center justify-center p-5 bg-gray-100">
    <Slider className="w-full" {...settings}>
      {newsData.length > 0 ? (
        newsData.map((image, index) => (
          <a href={getCurrentLink} target="_blank" rel="noopener noreferrer" key={index}>
            <img
              src={imgUrl + image.Popup_PhotoLink}
              className="object-cover w-full h-96 rounded-md transition-transform transform hover:scale-105 hover:shadow-lg cursor-pointer"
              onClick={() => handleImageClick(imgUrl + image.Popup_PhotoLink, image)}
            />
          </a>
        ))
      ) : (
        <div className="text-center text-gray-600">Dosya Bulunamadı!</div>
      )}
    </Slider>
  </div>
</div>
</div>






            )}
        </>
    );
};

export default Popup;
